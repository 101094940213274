html,
body {
  padding: 0;
  margin: 0;
  font-family: Ubuntu, sans-serif;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.ReactCollapse--collapse {
  transition: height 0.3s ease-in-out;
}
